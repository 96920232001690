<template>
  <el-dialog
    title="确认下单？"
    v-dialogDrag
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="360px"
  >
    <div class="orderConfirm">
      <div class="confirmList">
        <p class="listTitle">请选择下单方式：</p>
        <el-radio-group v-model="type">
          <el-radio label="BO" :disabled="disables">履约订金</el-radio>
          <el-radio label="BA">全款</el-radio>
        </el-radio-group>
      </div>
      <div>
        <h5 style="line-height:50px">摘单重量：</h5>
        <el-input
          v-model="pickResourcesWeight"
          :disabled="isTradeWay"
          @change="inputBlur"
          :placeholder="weightPlaceHolder"
        ></el-input>
      </div>
      <p v-if="type == 'BO'" style="margin-top:20px">
        履约订金：{{ marginPrice > 0 ? marginPrice : "---" }}元
      </p>
      <p v-if="type == 'BA'" style="margin-top:20px">
        全款：{{ marginPrice > 0 ? marginPrice : "---" }}元
      </p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="isLoadingOrder" @click="goOrder()"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      id: null,
      dialogVisible: false,
      isLoadingOrder: false,
      type: "BO",
      right: {
        moneyBuy: false,
        allMoneyBuy: false
      },
      pickResourcesWeight: null,
      weightPlaceHolder: "",
      isTradeWay: false,
      price: null,
      unsettledWeight: null,
      disables: false
    };
  },
  computed: {
    ...mapGetters(["getMarginRateGetter"]),
    marginPrice() {
      if (this.type == "BO") {
        return Number(
          Number(this.pickResourcesWeight) *
            Number(this.price) *
            Number(this.getMarginRateGetter.marginRate)
        ).toFixed(2);
      } else if (this.type == "BA") {
        return Number(
          Number(this.pickResourcesWeight) * Number(this.price)
        ).toFixed(2);
      } else {
        return 0;
      }
    }
  },
  methods: {
    getRights() {
      this.loadRight().then(loadResult => {
        if (loadResult == "loadRightSuccess") {
          this.right.moneyBuy = this.isHasRight(
            "tc-trade-pickBuyMarginResource"
          );
          this.right.allMoneyBuy = this.isHasRight(
            "tc-trade-pickBuyfullResource"
          );
        }
      });
    },
    showDialog(row) {
      if (row.marginStatus == "F") {
        this.disables = true;
        this.type = "BA";
      } else {
        this.disables = false;
      }
      if (row.tradeWay == "P") {
        if (
          row.minPickWeight &&
          Number(row.unsettledWeight) >= Number(row.minPickWeight)
        ) {
          this.weightPlaceHolder =
            "可摘单重量：" +
            row.unsettledWeight +
            row.unit +
            ";最小摘单重量" +
            row.minPickWeight +
            row.unit;
        } else {
          this.weightPlaceHolder =
            "可摘单重量：" + row.unsettledWeight + row.unit;
        }
        // this.weightPlaceHolder = '可摘单重量：' + row.unsettledWeight + row.unit
        this.isTradeWay = false;
      } else {
        this.weightPlaceHolder = "";
        this.pickResourcesWeight = row.unsettledWeight;
        this.isTradeWay = true;
      }
      this.unsettledWeight = row.unsettledWeight;
      this.id = row.hangResourcesId;
      this.price = row.price;
      this.dialogVisible = true;
      this.getRights();
    },
    inputBlur(value) {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (value == "") {
        return this.$EL_MESSAGE("请输入摘单重量");
      } else if (!reg.test(value) || Number(value) <= 0) {
        return this.$EL_MESSAGE("重量必须是正数且不能超过两位小数");
      } else if (Number(value) > Number(this.unsettledWeight)) {
        return this.$EL_MESSAGE("超过可摘单重量");
      }
    },
    handleClose() {
      this.pickResourcesWeight = null;
      this.dialogVisible = false;
    },
    goOrder() {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (!this.pickResourcesWeight) {
        this.$EL_MESSAGE("请输入摘单重量");
        return;
      } else if (
        !reg.test(this.pickResourcesWeight) ||
        Number(this.pickResourcesWeight) <= 0
      ) {
        return this.$EL_MESSAGE("重量必须是正数且不能超过两位小数");
      }
      this.isLoadingOrder = true;
      if (this.type == "BO") {
        if (this.right.moneyBuy) {
          protocolFwd.param_pickBuyMarginResource.param.hangResourcesId = this.id;
          protocolFwd.param_pickBuyMarginResource.param.pickResourcesWeight = this.pickResourcesWeight;
          http
            .postFront(protocolFwd.param_pickBuyMarginResource)
            .then(response => {
              const { code, message, value } = response.data;
              this.pickResourcesWeight = null;
              if (code == 0) {
                this.$confirm("查看订单", "下单成功", {
                  distinguishCancelAndClose: true,
                  confirmButtonText: "去看看",
                  cancelButtonText: "取消"
                })
                  .then(() => {
                    this.$router.push({
                      name: "buyerCenterPurchaseSpotOrder"
                    });
                  })
                  .catch(action => {});
                this.isLoadingOrder = false;
              } else {
                this.$EL_MESSAGE(message);
                this.isLoadingOrder = false;
              }
              this.dialogVisible = false;
            })
            .catch(() => {
              this.pickResourcesWeight = null;
              this.isLoadingOrder = false;
            });
        } else {
          this.pickResourcesWeight = null;
          this.$EL_MESSAGE("您无操作权限");
        }
      } else if (this.type == "BA") {
        if (this.right.allMoneyBuy) {
          protocolFwd.param_pickBuyfullResource.param.hangResourcesId = this.id;
          protocolFwd.param_pickBuyfullResource.param.pickResourcesWeight = this.pickResourcesWeight;
          http
            .postFront(protocolFwd.param_pickBuyfullResource)
            .then(response => {
              const { code, message, value } = response.data;
              this.pickResourcesWeight = null;
              if (code == 0) {
                this.pickResourcesWeight = null;
                this.$confirm("查看订单", "下单成功", {
                  distinguishCancelAndClose: true,
                  confirmButtonText: "去看看",
                  cancelButtonText: "取消"
                })
                  .then(() => {
                    this.$router.push({
                      name: "buyerCenterPurchaseSpotOrder"
                    });
                  })
                  .catch(action => {});
                this.isLoadingOrder = false;
              } else {
                this.$EL_MESSAGE(message);
                this.isLoadingOrder = false;
              }
              this.dialogVisible = false;
            })
            .catch(() => {
              this.pickResourcesWeight = null;
              this.isLoadingOrder = false;
            });
        } else {
          this.pickResourcesWeight = null;
          this.$EL_MESSAGE("您无操作权限");
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.orderConfirm .confirmList .listTitle {
  margin-bottom: 10px;
}
</style>
